import { Box, Button, Stack } from '@mui/material';
import SubmitBtn from 'containers/common/components/SubmitBtn';
import { Form, Formik } from 'formik';
import React from 'react';
import propTypes from 'prop-types';
import FormikField from 'shared/components/form/FormikField';
import { useAddReportRequestMutation } from 'services/private/users';
import { useSnackbar } from 'notistack';
import { ReportRequestFormInitialSchema, ReportRequestFormInitialValue } from '../utilities/formUtils';

function ReportRequestForm({ responseHandler, afterRequestSend, attendanceID }) {
  const [addReportRequest] = useAddReportRequestMutation();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box>
      <Formik
        initialValues={ReportRequestFormInitialValue}
        validationSchema={ReportRequestFormInitialSchema}
        onSubmit={async (values, { resetForm }) => {
          const response = await addReportRequest({
            attendance: attendanceID,
            reason: values.reason,
          });
          if (response?.data) {
            enqueueSnackbar('Request Sent successfully!', {
              variant: 'success',
              preventDuplicate: true,
            });
          }

          if (response?.error?.data?.non_field_errors[0]) {
            enqueueSnackbar(response?.error?.data?.non_field_errors[0], { variant: 'error', preventDuplicate: true });
          }
          responseHandler(response);
          if (!response.error) {
            resetForm();
          }
          resetForm();
        }}
      >
        {({ isSubmitting }) => (
          <Form className="d-flex align-items-start flex-column gap-3">
            <FormikField name="reason" label="Reason" type="textarea" isRequired />

            <Stack direction="row" mt={2} spacing={1}>
              <SubmitBtn label="Submit" isLoading={isSubmitting} />

              <Button variant="contained" color="secondary" onClick={afterRequestSend}>
                Cancel
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

ReportRequestForm.defaultProps = {
  afterRequestSend: () => null,
  responseHandler: () => {},
  attendanceID: null
};

ReportRequestForm.propTypes = {
  afterRequestSend: propTypes.func,
  responseHandler: propTypes.func,
  attendanceID: propTypes.number
};

export default ReportRequestForm;
