import React, { useContext, useState } from 'react';
import { Box, IconButton, Modal, Stack, Toolbar } from '@mui/material';
import propTypes from 'prop-types';
import { Add, Clear, Delete, Edit } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

// API HOOKS & CUSTOM HOOKS
import { useDeleteProStagesMutation } from 'services/private/lookups';
import useGetEmployeeRoles from 'customHooks/useGetEmployeeRoles';

// COMPONENTS & STYLES & CONTEXT
import { fileViewModalStyles } from 'styles/mui/common/modal-styles';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import ModalHeader from 'containers/common/components/ModalHeader';
import { SettingsContext } from 'containers/portal/settings/contexts/SettingsContext';
import { useUpdateEmployeeProStageMutation } from 'services/private/visa';
import ProSettingForm from '../ProSettingForm';

function SettingsToolbar({ selected, setSelected, proSelection, visaID }) {
  const { setEditModal, isEditModalOpen } = useContext(SettingsContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isDelDialogOpen, setDelDialogOpen] = useState(false);

  // API HOOKS & CUSTOM HOOKS
  const [deleteSetting] = useDeleteProStagesMutation();
  const [useUpdateEmployeeProStage] = useUpdateEmployeeProStageMutation();
  const { isHRManager } = useGetEmployeeRoles();

  // HANDLERS
  const handleDeleteSelected = async () => {
    selected?.forEach(async item => {
      const response = await deleteSetting(item?.id);
      setSelected([]);
      setDelDialogOpen(false);
      if (!response.error) {
        enqueueSnackbar('Setting deleted!', { variant: 'success' });
      } else {
        enqueueSnackbar(response?.error?.data?.message, { variant: 'error' });
      }
    });
  };

  const handleAddSelected = async () => {
    const selectedData = selected?.map(item => ({ stage: item?.id, visa: visaID }));

    const payload = { pro_stage: selectedData };

    const response = await useUpdateEmployeeProStage({ payload, id: visaID });

    if (!response.error) {
      enqueueSnackbar('PRO Stage Updated', { variant: 'success' });
    } else {
      enqueueSnackbar(response?.error?.data?.message, { variant: 'error' });
    }
  };

  return (
    <Box>
      <ConfirmationDialog
        open={isDelDialogOpen}
        message="Are you sure you want to delete the setting?"
        onConfirm={handleDeleteSelected}
        onClose={() => setDelDialogOpen(false)}
      />

      <Modal open={isEditModalOpen} onClose={() => setEditModal(false)}>
        <Box sx={fileViewModalStyles}>
          <ModalHeader title="Edit Shift" onClose={() => setEditModal(false)} />

          <ProSettingForm selected={selected[0]} setSelected={setSelected} />
        </Box>
      </Modal>

      <Toolbar>
        {selected?.length > 0 && (
          <Stack justifyContent="flex-end" width={1} direction="row" spacing={2}>
            {!proSelection && (
              <>
                {selected?.length === 1 && (
                  <IconButton title="Edit" onClick={() => setEditModal(true)}>
                    <Edit />
                  </IconButton>
                )}

                <IconButton title="Delete" onClick={() => setDelDialogOpen(true)} disabled={isHRManager}>
                  <Delete />
                </IconButton>
              </>
            )}

            {proSelection && (
              <IconButton title="Add PRO Stages" onClick={handleAddSelected}>
                <Add />
              </IconButton>
            )}

            <IconButton title="Clear Selection" onClick={() => setSelected([])}>
              <Clear />
            </IconButton>
          </Stack>
        )}
      </Toolbar>
    </Box>
  );
}

SettingsToolbar.propTypes = {
  setSelected: propTypes.func.isRequired,
  selected: propTypes.array,
  visaID: propTypes.number,
  proSelection: propTypes.bool,
};

SettingsToolbar.defaultProps = {
  selected: [],
  visaID: null,
  proSelection: false,
};

export default SettingsToolbar;
