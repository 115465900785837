import { privateAPi } from '.';

export const lookupsApi = privateAPi.injectEndpoints({
  endpoints: build => ({

    // LOOK UPS

    getFilteredLookups: build.query({
      query: type => `/api/lookups/search/${type}`,
      providesTags: ['GetFilteredLookups'],
    }),

    searchLookups: build.mutation({
      query: type => ({ url: `/api/lookups/search/${type}`, method: 'GET' }),
      providesTags: ['SearchLookup'],
    }),

    getEmployeesAndJobTitleLookup: build.query({
      query: () => '/api/accounts/employee-name-and-job-title',
      providesTags: ['GetEmployeesAndJobTitleLookup'],
    }),

    getLookupByType: build.query({
      query: type => `/api/lookups/search/${type}`,
      providesTags: ['GetLookupByType'],
    }),

    getLookupById: build.query({
      query: id => `/api/lookups/${id}/`,
      providesTags: ['GetLookupById'],
    }),

    getAllLookups: build.query({
      query: params => ({
        url: '/api/lookups/',
        params: {
          lookup_type: params?.lookup_type || undefined,
          ...params
        },
      }),
      providesTags: ['GetAllLookups'],
    }),

    deleteLookup: build.mutation({
      query: id => ({
        url: `/api/lookups/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'GetAllLookups',
        'SearchLookup',
        'GetFilteredLookups',
        'GetLookupByType',
        'GetLookupById',
      ],
    }),

    addLookup: build.mutation({
      query: body => ({
        url: '/api/lookups/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'GetAllLookups',
        'SearchLookup',
        'GetFilteredLookups',
        'GetLookupByType',
        'GetLookupById',
      ],
    }),

    editLookup: build.mutation({
      query: body => ({
        url: `/api/lookups/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'GetAllLookups',
        'SearchLookup',
        'GetFilteredLookups',
        'GetLookupByType',
        'GetLookupById',
      ],
    }),

    getProjectsLookup: build.query({
      query: () => ({
        url: '/api/project/names/',
        method: 'GET',
      }),
    }),

    getAssignedProjectsLookup: build.query({
      query: () => ({
        url: '/api/attendance/projects/names/',
        method: 'GET',
      }),
    }),

    // LEAVES SETTING

    addLeaveSetting: build.mutation({
      query: body => ({
        url: '/api/leaves/leave_type/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getLeaveSettingsList', 'getLeaveSetting'],
    }),

    getLeaveSettingsList: build.query({
      query: () => ({
        url: '/api/leaves/leave_type/',
      }),
      providesTags: ['getLeaveSettingsList'],
    }),

    getLeaveSetting: build.query({
      query: id => ({
        url: `/api/leaves/leave_type/${id}/`,
      }),
      providesTags: ['getLeaveSetting'],
    }),

    updateLeaveSetting: build.mutation({
      query: body => ({
        url: `/api/leaves/leave_type/${body?.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['getLeaveSettingsList', 'getLeaveSetting'],
    }),

    deleteLeaveSetting: build.mutation({
      query: id => ({
        url: `/api/leaves/leave_type/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getLeaveSettingsList', 'getLeaveSetting'],
    }),

    // SHIFTS SETTING

    getShiftsSettingsList: build.query({
      query: () => ({
        url: '/api/attendance/employee-shift/',
      }),
      providesTags: ['GetShiftsSettingsList'],
    }),
    addShiftsSetting: build.mutation({
      query: body => ({
        url: '/api/attendance/employee-shift/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GetShiftsSettingsList', 'GetShiftsSetting'],
    }),
    getShiftsSetting: build.query({
      query: id => ({
        url: `/api/attendance/employee-shift/${id}/`,
      }),
      providesTags: ['GetShiftsSetting'],
    }),
    updateShiftsSetting: build.mutation({
      query: body => ({
        url: `/api/attendance/employee-shift/${body?.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['GetShiftsSettingsList', 'GetShiftsSetting'],
    }),

    deleteShiftsSetting: build.mutation({
      query: id => ({
        url: `/api/attendance/employee-shift/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetShiftsSettingsList', 'GetShiftsSetting'],
    }),

    // PAYROLL SETTING

    getPayrollSettingsList: build.query({
      query: params => ({
        url: '/api/payroll-group/',
        method: 'GET',
        params
      }),
      providesTags: ['GetPayrollSettingsList'],
    }),
    addPayrollSetting: build.mutation({
      query: body => ({
        url: '/api/payroll-group/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GetPayrollSettingsList', 'GetPayrollSetting'],
    }),
    getPayrollSetting: build.query({
      query: id => ({
        url: `/api/payroll-group/${id}/`,
      }),
      providesTags: ['GetPayrollSetting'],
    }),
    updatePayrollSetting: build.mutation({
      query: body => ({
        url: `/api/payroll-group/${body?.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['GetPayrollSettingsList', 'GetPayrollSetting'],
    }),

    deletePayrollSetting: build.mutation({
      query: id => ({
        url: `/api/payroll-group/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetPayrollSettingsList', 'GetPayrollSetting'],
    }),

    // PRO STAGES SETTINGS
    getProStagesList: build.query({
      query: () => ({
        url: '/api/visa-tracking/pro-stages/',
      }),
      providesTags: ['GetProStagesList'],
    }),
    addProStages: build.mutation({
      query: body => ({
        url: '/api/visa-tracking/pro-stages/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GetProStagesList', 'GetProStages'],
    }),
    getProStages: build.query({
      query: id => ({
        url: `/api/visa-tracking/pro-stages/${id}/`,
      }),
      providesTags: ['GetProStages'],
    }),
    updateProStages: build.mutation({
      query: body => ({
        url: `/api/visa-tracking/pro-stages/${body?.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['GetProStagesList', 'GetProStages'],
    }),

    deleteProStages: build.mutation({
      query: id => ({
        url: `/api/visa-tracking/pro-stages/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetProStagesList', 'GetProStages'],
    }),

  }),
});

export const {

  // LOOK UPS
  useGetFilteredLookupsQuery,
  useSearchLookupsMutation,
  useGetEmployeesAndJobTitleLookupQuery,
  useGetLookupByTypeQuery,
  useGetLookupByIdQuery,
  useLazyGetAllLookupsQuery,
  useDeleteLookupMutation,
  useAddLookupMutation,
  useEditLookupMutation,
  useGetProjectsLookupQuery,
  useGetAssignedProjectsLookupQuery,
  useGetAllLookupsQuery,

  // LEAVES SETTING
  useGetLeaveSettingsListQuery,
  useGetLeaveSettingQuery,
  useUpdateLeaveSettingMutation,
  useDeleteLeaveSettingMutation,
  useAddLeaveSettingMutation,

  // SHIFT SETTINGS
  useGetShiftsSettingsListQuery,
  useAddShiftsSettingMutation,
  useGetShiftsSettingQuery,
  useUpdateShiftsSettingMutation,
  useDeleteShiftsSettingMutation,

  // PAYROLL SETTINGS
  useGetPayrollSettingsListQuery,
  useLazyGetPayrollSettingsListQuery,
  useAddPayrollSettingMutation,
  useGetPayrollSettingQuery,
  useUpdatePayrollSettingMutation,
  useDeletePayrollSettingMutation,

  // PRO STAGES SETTINGS
  useGetProStagesListQuery,
  useAddProStagesMutation,
  useGetProStagesQuery,
  useUpdateProStagesMutation,
  useDeleteProStagesMutation,

} = lookupsApi;
