import { privateAPi } from '.';

export const visaApi = privateAPi.injectEndpoints({
  endpoints: build => ({
    getVisaProcess: build.query({
      query: () => '/api/visa-tracking/visa/',
      providesTags: ['GetVisaProcess'],
    }),

    getVisaProcessById: build.query({
      query: id => `/api/visa-tracking/visa/${id}/`,
      providesTags: ['GetVisaProcessById'],
    }),

    getEmployeeVisaProcess: build.query({
      query: params => ({
        url: '/api/visa-tracking/visa/',
        method: 'GET',
        params
      }),
      providesTags: ['GetEmployeeVisaProcess'],
    }),

    addNewVisa: build.mutation({
      query: body => ({
        url: '/api/visa-tracking/visa/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'GetEmployeeVisaProcess',
        'GetVisaProcess',
        'GetVisaProcessById',
        'GetAuthorizedUser',
      ],
    }),

    updateNewVisa: build.mutation({
      query: body => ({
        url: `/api/visa-tracking/visa/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['GetEmployeeVisaProcess', 'GetVisaProcess', 'GetVisaProcessById'],
    }),

    updateNewVisaForm: build.mutation({
      query: body => ({
        url: `/api/visa-tracking/visa/${body.id}/`,
        method: 'PUT',
        body: body?.formData,
      }),
      invalidatesTags: ['GetEmployeeVisaProcess', 'GetVisaProcess', 'GetVisaProcessById'],
    }),

    updateVisaDocument: build.mutation({
      query: body => ({
        url: `/api/visa-tracking/visa-document/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['GetEmployeeVisaProcess', 'GetVisaProcess', 'GetVisaProcessById'],
    }),

    deleteNewVisa: build.mutation({
      query: id => ({
        url: `/api/visa-tracking/visa/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetVisaProcess', 'GetEmployeeVisaProcess', 'GetVisaProcessById'],
    }),

    updateEmployeeProStage: build.mutation({
      query: body => ({
        url: `/api/visa-tracking/update-visa-pro-stages/${body.id}/`,
        method: 'PATCH',
        body: body?.payload,
      }),
      invalidatesTags: ['GetEmployeeVisaProcess', 'GetVisaProcess', 'GetVisaProcessById'],
    }),

    updateVisaPro: build.mutation({
      query: body => ({
        url: `/api/visa-tracking/visa-pro/${body.id}/`,
        method: 'PUT',
        body: body.formData,
      }),
      invalidatesTags: ['GetEmployeeVisaProcess', 'GetVisaProcess', 'GetVisaProcessById'],
    }),

    updateVisaProStatus: build.mutation({
      query: body => ({
        url: `/api/visa-tracking/visa-pro/${body.id}/`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['GetEmployeeVisaProcess', 'GetVisaProcess', 'GetVisaProcessById'],
    }),

  }),
});

export const {
  // VISA PROCESS
  useGetVisaProcessQuery,
  useLazyGetEmployeeVisaProcessQuery,
  useGetVisaProcessByIdQuery,
  useAddNewVisaMutation,
  useUpdateNewVisaMutation,
  useUpdateNewVisaFormMutation,
  useUpdateVisaDocumentMutation,

  // EMPLOYEE PRO STAGE
  useUpdateEmployeeProStageMutation,

  // PRO STAGES
  useUpdateVisaProMutation,
  useUpdateVisaProStatusMutation,
} = visaApi;
