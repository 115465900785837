import React, { useContext } from 'react';
import { Box } from '@mui/material';

// CONTEXTS
import { ViewDocContext } from 'contexts/ViewDocContext';
import { pdfDocRendererStyles } from 'styles/common/viewDocModalStyles';

function PdfDocRenderer() {
  const { fileURI } = useContext(ViewDocContext);

  return (
    <Box className="px-0">
      <object data={fileURI} title="File Preview" className="h-100 w-100" style={pdfDocRendererStyles}>
        <iframe src={fileURI} title="File Preview" className="h-100 w-100" />
      </object>
    </Box>
  );
}

export default PdfDocRenderer;
