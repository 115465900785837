import { List, ListItemButton, Popover, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function ActionsMenu({
  targetEl,
  onClose,
  menuItems,
  anchorOrigin,
  transformOrigin,
  onItemClick,
  isItemSelected,
  isOptionDisabled,
}) {
  const open = Boolean(targetEl);

  return (
    <Popover
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      anchorEl={targetEl}
      onClose={onClose}
    >
      <List dense>
        {menuItems.map(item => (
          <ListItemButton
            selected={isItemSelected(item)}
            disabled={isOptionDisabled(item) || item?.isDisabled}
            disableRipple={isItemSelected(item)}
            onClick={() => (isItemSelected(item) ? null : onItemClick(item))}
            key={item.value}
          >
            <Typography variant="body2">{item.label}</Typography>
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
}

ActionsMenu.defaultProps = {
  targetEl: null,
  menuItems: [],
  onClose: () => null,
  isItemSelected: () => false,
  onItemClick: () => null,
  isOptionDisabled: () => false,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

ActionsMenu.propTypes = {
  targetEl: PropTypes.any,
  isOptionDisabled: PropTypes.func,
  onClose: PropTypes.func,
  isItemSelected: PropTypes.func,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      isDisabled: PropTypes.bool,
    })
  ),
  onItemClick: PropTypes.func,
  anchorOrigin: PropTypes.shape({ vertical: PropTypes.string, horizontal: PropTypes.string }),
  transformOrigin: PropTypes.shape({ vertical: PropTypes.string, horizontal: PropTypes.string }),
};

export default ActionsMenu;
