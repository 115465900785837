import moment from 'moment';
import * as yup from 'yup';

function isTestEndDate(value) {
  const { start_time: startTime } = this.parent;
  return moment(startTime, 'HH:mm:ss').isSameOrBefore(moment(value, 'HH:mm:ss'));
}

export const emergencyLeave = 'emergency leave';

export const settingsFormValSchema = yup.object({
  lookup_name: yup.string().max(100, 'Cannot exceed 100 characters!').required('Required'),
  lookup_type: yup.string().required('Required'),
});

export const settingsFormInitVals = { lookup_type: '', lookup_name: '' };

export const leaveSettingsFormValSchema = yup.object({
  name: yup.string().required('Required'),
  quantity: yup.string().when('name', {
    is: value => value === emergencyLeave,
    then: yup.string(),
    otherwise: yup.string().required('Required'),
  }),
});

export const leaveSettingsFormInitVals = { quantity: '', name: '' };

export const shiftSettingsFormValSchema = yup.object({
  shift_name: yup.string().required('Required'),
  start_time: yup.string().required('Required'),
  end_time: yup
    .string()
    .test('end_time', 'End time must be after start time', isTestEndDate)
    .required('Required'),
  relaxation_time: yup.number().integer().positive().required('Required'),
  upcoming_relaxation_time: yup.number('Invalid Input').integer().positive().required('Required'),
  total_shift_hours: yup.number('Invalid Input').integer().positive().required('Required'),
  overtime_start_after: yup.number('Invalid Input').integer().positive().required('Required'),
  is_active: yup.boolean(),
});

export const shiftSettingsFormInitVals = {
  shift_name: '',
  start_time: '',
  end_time: '',
  relaxation_time: '',
  upcoming_relaxation_time: '',
  total_shift_hours: '',
  overtime_start_after: '',
  is_active: true,
};

export const shiftAssignFormInitVals = { shift: '', employees: [], allEmployees: false };

export const shiftAssignFormValSchema = yup.object({
  shift: yup.string().required('Required'),
  employees: yup.array().when('allEmployees', {
    is: true,
    then: yup.array().nullable(),
    otherwise: yup.array().required('Required!'),
  }),
});

export const proSettingsFormInitVals = {
  stage_name: '',
  stage_type: '',
};

export const proSettingsFormValSchema = yup.object({
  stage_name: yup.string().required('Required'),
  stage_type: yup.string().required('Required'),
});

export const payrollSettingsFormInitVals = {
  name: '',
  formula: '',
};

export const payrollSettingsFormValSchema = yup.object({
  name: yup.string().required('Required'),
  formula: yup.string().max(100, 'Maximum 100 characters allowed').required('Required'),
});

export const departmentFormInitVals = {
  department_name: '',
  module: [],
  department_roles: '',
};

export const departmentFormValSchema = yup.object({
  department_name: yup.string().max(255, 'Maximum 255 characters allowed').required('Required'),
});

export const leaveSettingNameOptions = [
  {
    label: 'Sick leave',
    value: 'sick leave',
  },
  {
    label: 'Casual leave',
    value: 'casual leave',
  },
  {
    label: 'Maternity leave',
    value: 'maternity leave',
  },
  {
    label: 'Paternity leave',
    value: 'paternity leave',
  },
  {
    label: 'Emergency leave',
    value: emergencyLeave,
  },
];
