import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, Stack } from '@mui/material';
import propTypes from 'prop-types';

// HOOKS & API
import { useAddProStagesMutation, useUpdateProStagesMutation } from 'services/private/lookups';

// COMPONENTS & UTILS & CONTEXT
import SubmitBtn from 'containers/common/components/SubmitBtn';
import FormikField from 'shared/components/form/FormikField';
import FormikSelect from 'shared/components/form/FormikSelect';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import { SettingsContext } from '../../contexts/SettingsContext';
import { proSettingsFormInitVals, proSettingsFormValSchema } from '../../utilities/formUtilities';
import { proTypeOptions } from '../../utilities/data';

function ProSettingForm({ selected, setSelected }) {
  const { setAddModal, setEditModal } = useContext(SettingsContext);
  const [initValues, setInitValues] = useState(proSettingsFormInitVals);

  // API HOOKS & CUSTOM HOOKS
  const [addProStage, { error: addError, isSuccess: isAddSuccess }] = useAddProStagesMutation();
  const [editProStage, { error: editError, isSuccess: isEditSuccess }] = useUpdateProStagesMutation();

  useHandleApiResponse(addError, isAddSuccess, 'Setting added successfully!');
  useHandleApiResponse(editError, isEditSuccess, 'Setting updated successfully!');

  useEffect(() => {
    if (selected) {
      setInitValues({
        stage_name: selected?.stage_name,
        stage_type: selected?.stage_type,
      });
    }
  }, [selected]);

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={proSettingsFormValSchema}
      onSubmit={async values => {
        if (selected) {
          await editProStage({ ...values, id: selected?.id });
          setSelected([]);
          setEditModal(false);
        } else {
          await addProStage(values);
          setAddModal(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form className="d-flex align-items-start flex-column gap-3">
          <FormikField label="PRO Name" placeholder="Name" isRequired name="stage_name" />

          <FormikSelect
            label="Type"
            options={proTypeOptions}
            placeholder="Select Type"
            name="stage_type"
            isPortal
            isRequired
          />

          <Stack direction="row" mt={1} spacing={2} width="100%">
            <SubmitBtn label="Save" size="small" isLoading={isSubmitting} />

            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={selected ? () => setEditModal(false) : () => setAddModal(false)}
            >
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

ProSettingForm.propTypes = {
  setSelected: propTypes.func,
  selected: propTypes.object,
};

ProSettingForm.defaultProps = {
  setSelected: () => {},
  selected: null,
};

export default ProSettingForm;
