import { createContext } from 'react';

export const SettingsContext = createContext({
  getLookups: async () => {},
  lookups: [],
  setLookupData: () => {},
  setAddModal: () => {},
  setEditModal: () => {},
  isEditModalOpen: false,
});

export const test = '';
