import moment from 'moment';
import store from 'store';

// UTILITIES
import colors from 'styles/common/colors';
import { APPROVED, COMPANY_HOLIDAYS, DENIED, EVENTS, INTERNAL_MEETINGS, OPEN, PENDING } from './constants';

const { primary } = store.getState().theme.palette;

export const eventStyleGetter = event => {
  let color;

  switch (event.priority) {
    case 'high':
      color = '#fa697d';
      break;

    case 'family':
      color = '#4ce1b6';
      break;

    case 'non':
      color = '#70bbfd';
      break;

    default:
      color = '$muted';
      break;
  }

  const style = {
    backgroundColor: color,
    border: 'none',
  };

  return {
    style,
  };
};

export const getEngagementStyles = event => {
  let color;

  switch (event?.type) {
    case INTERNAL_MEETINGS:
      color = colors.dark;
      break;

    case COMPANY_HOLIDAYS:
      color = colors.success;
      break;

    case EVENTS:
      color = colors.primary;
      break;

    default:
      color = colors.muted;
  }

  if (event?.extendedProps?.isCancel) {
    color = 'lightgrey';
  }

  const style = {
    backgroundColor: color,
    border: 'none',
  };

  return {
    style,
    color,
  };
};

export const getTableStatusStyles = status => {
  let color = '#000';

  if (status === APPROVED) {
    color = '#16C09833';
  }

  if (status === DENIED) {
    color = '#F6000033';
  }

  if (status === PENDING) {
    color = '#C9C9C933';
  }

  return {
    background: color,
    width: '87px',
    color: 'black',
    mx: 'auto',
    textAlign: 'center',
    padding: '5px 5px',
    borderRadius: '5px',
  };
};

export const getTicketTableStatusStyles = status => {
  let color = '#f1f1f1';
  let textColor = '#000';

  if (status === OPEN) {
    color = '#006FAF';
    textColor = '#fff';
  }

  return {
    background: color,
    width: '100%',
    color: textColor,
    textAlign: 'center',
    padding: '3px 5px',
    borderRadius: '5px',
  };
};

export const getPerDaySalary = (basicSalary = 0) => (+basicSalary * 12) / 365;

export const stableSort = (array, comparator) => {
  const transformedArray = array?.map((obj, idx) => [obj, idx]);

  transformedArray?.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return transformedArray?.map(item => item[0]);
};

const descendingComparator = (a, b, orderBy) => {
  if (a[orderBy] < b[orderBy]) {
    return -1;
  }
  if (a[orderBy] > b[orderBy]) {
    return +1;
  }

  return 0;
};

export const getComparator = (order, orderBy) => {
  if (order === 'asc') {
    return (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (a, b) => descendingComparator(a, b, orderBy);
};

export const isSelected = (id, selected = []) => selected?.findIndex(item => item?.id === id) !== -1;

export const handleSelectRow = (selectedRow = {}, selected = [], setSelected = () => {}) => {
  const selectedId = selectedRow?.id;

  const selectedIndex = selected?.findIndex(item => item?.id === selectedId);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, selectedRow);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  }

  setSelected(newSelected);
};

export const handleSelectAllRows = (e, data = [], setSelected = () => {}) => {
  if (e?.target?.checked && data) {
    setSelected(data);
    return;
  }

  setSelected([]);
};

export const isWordExcelFile = (type = '') => {
  if (type?.includes('doc') || type?.includes('docx') || type?.includes('xlsx')) {
    return true;
  }

  return false;
};

export const isWordFile = (type = '') => {
  const slicedType = type?.slice(-10);

  if (slicedType?.includes('doc') || slicedType?.includes('docx')) {
    return true;
  }

  return false;
};

export const isImageFile = (type = '') => {
  if (type?.includes('image')) {
    return true;
  }

  return false;
};

export const isPdfDocFile = (type = '') => {
  if (type?.includes('pdf') || type?.includes('text')) {
    return true;
  }

  return false;
};

export const isPdfFile = (type = '') => {
  if (type?.includes('pdf')) {
    return true;
  }

  return false;
};

export const isPptFile = (type = '') => {
  if (type?.includes('ppt') || type?.includes('pptx')) {
    return true;
  }

  return false;
};

export const isFileViewSupported = (type = '') => {
  if (type?.includes('pdf') || type?.includes('image') || type?.includes('text') || isWordExcelFile(type)) {
    return true;
  }

  return false;
};

export const fileTypeValidationTest = value => {
  if (value?.type?.includes('video')) return false;

  return true;
};

export const formatTime = time => moment(time, 'HH:mm:ss').format('HH:mm:ss');

export const formatDate = date => moment(date).format('YYYY-MM-DD');

export const getGroupedData = (arr = [], property = '') => {
  const sortedArr = [...arr]?.sort((a, b) => new Date(a[property]) - new Date(b[property]));

  const groupedData = sortedArr?.reduce((acc, obj) => {
    const key = obj[property];

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key]?.push(obj);

    return acc;
  }, {});

  return Object.entries(groupedData);
};

export const URLSearchParamsToObject = urlParams => {
  const paramsObject = {};

  urlParams.forEach((value, key) => {
    paramsObject[key] = value;
  });

  return paramsObject;
};

export const getDatesFromStartToEnd = (
  startDate = moment().format('YYYY-MM-DD'),
  endDate = moment().format('YYYY-MM-DD')
) => {
  const currentDate = moment(startDate);
  const dates = [];

  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate.add(1, 'days');
  }

  return dates;
};

export const getFilenameFromUrl = (url = '') => {
  const urlParts = url?.split('/');

  return urlParts.pop();
};

export const isValidDocOrImage = (type = '') => {
  const isValidPdfDoc = isPdfDocFile(type);

  const isValidWordExcel = isWordExcelFile(type);

  const isValidPpt = isPptFile(type);

  const isValidImage = isImageFile(type);

  return isValidPdfDoc || isValidWordExcel || isValidPpt || isValidImage;
};

export const appendParamToUrl = (url = '') => `${url}?id=${crypto.randomUUID()}`;

export const getSearchParamsObject = urlSearchParams => Object.fromEntries(urlSearchParams);

export const getCommonHoverColorStyles = () => ({
  ':hover': {
    color: primary.main,
  },
});

export const modifyFormikTextEditorStyleTag = () => {
  const targetStr = '--ck-color-focus-border:hsl(var(--ck-color-focus-border-coordinates))';
  const sourceStr = `--ck-color-focus-border:${primary.main}`;
  const tags = document.head.getElementsByTagName('style');

  for (let i = 0; i < tags.length; i += 1) {
    const tag = tags[i];
    const CKAttribute = tag?.getAttribute('data-cke');

    if (CKAttribute === 'true') {
      tag.innerText = tag.innerText.replace(targetStr, sourceStr);
    }
  }
};

export const truncateText = (text = '', limit = 10, concatString = '') => {
  // returns truncated text based on limit

  if (text.length > limit) {
    return text.slice(0, limit).concat(concatString || '...');
  }

  return text;
};

export const getFormattedTime = (time, format = 'HH:mm:ss', placeholder = '_') => {
  if (time) {
    const formattedTime = moment(time, format);
    return {
      hours: Math.abs(formattedTime.hours()),
      minutes: Math.abs(formattedTime.minutes()),
      timeString: `${Math.abs(formattedTime.hours())}h ${Math.abs(formattedTime.minutes())}m`,
      formated: (newFormat = 'hh:mm A') => formattedTime.format(newFormat),
      duration: moment.duration(time, format)
    };
  }
  return {
    hours: 0,
    minutes: 0,
    timeString: placeholder,
    formated: () => placeholder,
    duration: 0,
  };
};

export const formattedAmount = (number = 0) => number.toLocaleString('en-US');
