import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { ViewDocContext } from 'contexts/ViewDocContext';
import { msDocRendererStyles } from 'styles/common/viewDocModalStyles';

function MsDocsRenderer() {
  const { fileURI } = useContext(ViewDocContext);

  return (
    <Box className="px-0 h-100 w-100">
      <iframe
        title="File Preview"
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileURI)}`}
        className="h-100 w-100"
        style={msDocRendererStyles}
      />
    </Box>
  );
}

export default MsDocsRenderer;
