import { Grid, Typography } from '@mui/material';
import propTypes from 'prop-types';
import React from 'react';

// HOOKS & API
import useGetEmployeeRoles from 'customHooks/useGetEmployeeRoles';

// COMPONENTS & UTILS
import VisaStatusPicker from 'containers/portal/visa-process/components/VisaStatusPicker';
import VisaDocStatusPicker from './VisaDocStatusPicker';

function VisaDetailGrid({ item, visa, colSpan, handleViewFile, isDoc }) {
  const { isHRController, isSuperUser, isHRManager } = useGetEmployeeRoles();
  const isController = isHRController || isHRManager || isSuperUser;

  return (
    <Grid item container xs={12} md={colSpan} columnSpacing={1} rowGap={1}>
      <Grid item xs={3} md={3}>
        <Typography fontWeight={500} variant="body2">
          {item.label}:
        </Typography>
      </Grid>
      {item.menu && isController ? (
        <Grid item xs={9} md={9}>
          {isDoc ? (
            <VisaDocStatusPicker status={visa[item.value]} itemID={visa?.id} />
          ) : (
            <VisaStatusPicker status={visa[item.value]} itemID={visa?.id} />
          )}
        </Grid>
      ) : (
        <Grid item xs={12} md={6}>
          {item.isFile ? (
            <Typography
              color="primary"
              sx={{ wordBreak: 'break-word', cursor: 'pointer' }}
              onClick={() => handleViewFile(visa[item.value], visa[item?.fileType])}
              variant="body2"
            >
              {visa[item?.fileName] || '_'}
            </Typography>
          ) : (
            <Typography variant="body2">{visa[item.value] || '_'}</Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
}

VisaDetailGrid.propTypes = {
  item: propTypes.object,
  visa: propTypes.object,
  colSpan: propTypes.number,
  isDoc: propTypes.bool,
  handleViewFile: propTypes.func,
};

VisaDetailGrid.defaultProps = {
  item: {},
  visa: {},
  colSpan: 6,
  isDoc: false,
  handleViewFile: () => {},
};

export default VisaDetailGrid;
