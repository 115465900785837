import { Domain, FactCheck, FormatListBulleted, QueryBuilder, Search } from '@mui/icons-material';

export const settingsTableHeadCells = [
  {
    id: 'lookup_type',
    label: 'Setting type',
  },
  {
    id: 'lookup_name',
    label: 'Setting name',
  },
];

export const settingsTypeTableHeadCells = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'quantity',
    label: 'Quantity',
  },
];

export const shiftSettingsTableHeadCells = [
  {
    id: 'shift_name',
    label: 'Shift Name',
  },
  {
    id: 'start_time',
    label: 'Start Time',
  },
  {
    id: 'end_time',
    label: 'End Time',
  },
  {
    id: 'total_shift_hours',
    label: 'Total Shift Hours(hrs)',
  },
  {
    id: 'relaxation_time',
    label: 'Early Check-in(mins)',
  },
  {
    id: 'upcoming_relaxation_time',
    label: 'Upcoming Relaxation(mins)',
  },
  {
    id: 'overtime_start_after',
    label: 'Overtime(mins)',
  },
];

export const proSettingsTableHeadCells = [
  {
    id: 'stage_name',
    label: 'PRO Stage Name',
  },
  {
    id: 'stage_type',
    label: 'PRO Type',
  },
];

export const departmentSettingsTableHeadCells = [
  {
    id: 'department_name',
    label: 'Department Name',
  },
  {
    id: 'modules',
    label: 'Modules',
  },
  {
    id: 'roles',
    label: 'Roles',
  },
  {
    id: 'created_by',
    label: 'Created By',
  },
];

export const payrollSettingsTableHeadCells = [
  {
    id: 'id',
    label: 'Code',
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'formula',
    label: 'Formula',
  },
];

export const settingCards = [
  {
    label: 'Lookups',
    path: '/portal/pages/settings/lookups',
    Icon: Search,
  },
  {
    label: 'Leaves',
    path: '/portal/pages/settings/leaves',
    Icon: FormatListBulleted,
  },
  {
    label: 'Shifts',
    path: '/portal/pages/settings/shifts',
    Icon: QueryBuilder,
  },
  {
    label: 'PRO Stages',
    path: '/portal/pages/settings/pro-stages',
    Icon: FactCheck,
  },
  {
    label: 'Departments',
    path: '/portal/pages/settings/departments',
    Icon: Domain,
  },
];

export const proTypeOptions = [
  {
    label: 'Form',
    value: 'form',
  },
  {
    label: 'Status',
    value: 'status',
  },
];
