import { commonJobsApiProvidesTag, commonOnboardingApiTag } from 'utilities/constants';
import { privateAPi } from '.';

export const onboardingApi = privateAPi.injectEndpoints({
  endpoints: build => ({
    getOnboardingQueue: build.query({
      query: params => ({
        url: '/api/on-boarding/offer-queue-on-boarding/',
        method: 'GET',
        params: {
          search: params?.search || undefined,
          status: params?.status || undefined,
          ...params
        },
      }),
      providesTags: ['getOnboardingQueue', commonOnboardingApiTag],
    }),

    getOnboardingQueueItemDetail: build.query({
      query: id => ({
        url: `/api/on-boarding/offer-queue-on-boarding/${id}/`,
        method: 'GET',
      }),
      providesTags: ['getOnboardingQueue', commonOnboardingApiTag],
    }),

    updateOnboardingQueueItemDetail: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/api/on-boarding/offer-queue-on-boarding/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['getOnboardingQueue', 'getOnboardingQueueItemDetail', commonJobsApiProvidesTag],
    }),

    addToOnboardingQueue: build.mutation({
      query: body => ({
        url: '/api/on-boarding/offer-queue-on-boarding/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getOnboardingQueue', commonJobsApiProvidesTag],
    }),

    updateOnboardingQueueItem: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/api/on-boarding/offer-queue-on-boarding/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['getOnboardingQueue', commonJobsApiProvidesTag],
    }),

    createHirePacket: build.mutation({
      query: body => ({
        url: '/api/on-boarding/offer-packet/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [commonOnboardingApiTag, commonJobsApiProvidesTag],
    }),

    getHirePacketDetail: build.query({
      query: id => ({
        url: `/api/on-boarding/offer-packet/${id}/`,
        method: 'GET',
      }),
      providesTags: ['getHirePacketDetail', commonOnboardingApiTag],
    }),

    updateHirePacket: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/api/on-boarding/offer-packet/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [commonOnboardingApiTag, commonOnboardingApiTag],
    }),

    updateHirePacketTask: build.mutation({
      query: ({ id, ...body }) => ({
        url: `api/on-boarding/task-update/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [commonOnboardingApiTag, commonJobsApiProvidesTag],
    }),

    updateHirePacketTaskAttachments: build.mutation({
      // this Api doesn't delete previous task files
      query: ({ id, files }) => {
        const formData = new FormData();

        for (let index = 0; index < files.length; index += 1) {
          const file = files[index];
          formData.append(`attachment[${index}]attachment_file`, file);
        }

        return {
          url: `api/on-boarding/task-attachment-update/${id}/`,
          method: 'PATCH',
          body: formData,
        };
      },
      invalidatesTags: [commonOnboardingApiTag, commonJobsApiProvidesTag],
    }),

    deleteHirePacketTaskAttachments: build.mutation({
      query: id => ({
        url: `api/on-boarding/attachment-update/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getHirePacketDetail', commonJobsApiProvidesTag],
    }),

    getOnboardingEmployeeProfile: build.query({
      query: id => ({
        url: `api/on-boarding/new-employee-profile/${id}/`,
        method: 'GET',
      }),
      providesTags: ['getOnboardingEmployeeProfile'],
    }),

    updateOnboardingEmployeeProfile: build.mutation({
      query: ({ id, ...body }) => ({
        url: `api/on-boarding/new-employee-profile/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['getOnboardingEmployeeProfile'],
    }),

    getOnboardingEmployeeEducationAndExperience: build.query({
      query: id => ({
        url: `api/on-boarding/new-employee-profile-education-and-experience-update/${id}/`,
        method: 'GET',
      }),
      providesTags: ['getOnboardingEmployeeEducation'],
    }),

    updateOnboardingEmployeeEducationAndExperience: build.mutation({
      query: ({ id, ...body }) => ({
        url: `api/on-boarding/new-employee-profile-education-and-experience-update/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [commonJobsApiProvidesTag, commonOnboardingApiTag],
    }),

    addToOnboardingDirectly: build.mutation({
      query: body => ({
        url: 'api/on-boarding/direct-hire-application/',
        method: 'POST',
        body,
      }),
      providesTags: [commonOnboardingApiTag],
      invalidatesTags: [commonJobsApiProvidesTag, commonOnboardingApiTag],
    }),
  }),
});

export const {
  useLazyGetOnboardingQueueQuery,
  useCreateHirePacketMutation,
  useAddToOnboardingQueueMutation,
  useUpdateOnboardingQueueItemMutation,
  useGetOnboardingQueueItemDetailQuery,
  useGetHirePacketDetailQuery,
  useUpdateHirePacketMutation,
  useUpdateHirePacketTaskMutation,
  useGetOnboardingEmployeeProfileQuery,
  useUpdateOnboardingEmployeeProfileMutation,
  useGetOnboardingEmployeeEducationAndExperienceQuery,
  useUpdateOnboardingEmployeeEducationAndExperienceMutation,
  useUpdateHirePacketTaskAttachmentsMutation,
  useDeleteHirePacketTaskAttachmentsMutation,
  useUpdateOnboardingQueueItemDetailMutation,
  useAddToOnboardingDirectlyMutation,
} = onboardingApi;
