import React, { useEffect, useReducer, useState } from 'react';
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import propTypes from 'prop-types';

// API HOOKS
import { useGetProStagesListQuery } from 'services/private/lookups';

// COMPONENTS & UTILITIES
import EmptyRecordTable from 'containers/common/components/EmptyRecordTable';
import SettingsTableHead from './SettingsTableHead';
import { paginationReducer } from '../../../utilities/reducerActions';
import SettingsToolbar from './SettingsToolbar';

function SettingsTable({ proSelection, visa }) {
  const { data } = useGetProStagesListQuery();

  const [selected, setSelected] = useState([]);
  const [pagination, setPagination] = useReducer(paginationReducer, { rowsPerPage: 10, page: 0 });
  const { rowsPerPage, page } = pagination;

  // HANDLERS
  const handleRowsPerPageChange = e => {
    setPagination({ type: 'rowsPerPage', payload: e?.target?.value });
  };

  const handlePageChange = (e, newValue) => {
    setPagination({ type: 'page', payload: newValue });
  };

  const handleSelectAllRows = e => {
    if (e?.target?.checked && data?.results) {
      setSelected(data?.results);
      return;
    }

    setSelected([]);
  };

  useEffect(() => {
    const filteredData = data?.results?.filter(item => visa?.pro_visa_ids?.includes(item.id));
    setSelected(filteredData);
  }, [data?.results, visa?.pro_visa_ids]);

  const handleClickRow = selectedRow => {
    const selectedRowId = selectedRow?.id;

    const selectedIndex = selected.findIndex(obj => obj?.id === selectedRowId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, selectedRow);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  // CONSTANTS
  const isSelected = id => selected?.findIndex(item => item.id === id) !== -1;

  return (
    <Box>
      <SettingsToolbar visaID={visa?.id} proSelection={proSelection} selected={selected} setSelected={setSelected} />

      <TableContainer>
        <Table>
          <SettingsTableHead
            numSelected={selected?.length || 0}
            rowCount={data?.results?.length || 0}
            onSelectAllRows={handleSelectAllRows}
          />

          {data?.results?.length > 0 ? (
            <TableBody>
              {data?.results?.slice(rowsPerPage * page, page * rowsPerPage + rowsPerPage)?.map(item => {
                const isItemSelected = isSelected(item?.id);

                return (
                  <TableRow
                    hover
                    selected={isItemSelected}
                    key={item?.id}
                    onClick={() => handleClickRow(item)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={isItemSelected} />
                    </TableCell>

                    <TableCell>{item?.stage_name}</TableCell>
                    <TableCell>{item?.stage_type}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <EmptyRecordTable colSpan={3} />
          )}
        </Table>

        <TablePagination
          component={Box}
          count={data?.results?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={[10, 20, 30]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </TableContainer>
    </Box>
  );
}

SettingsTable.propTypes = {
  proSelection: propTypes.bool,
  visa: propTypes.object,
};

SettingsTable.defaultProps = {
  proSelection: false,
  visa: {},
};

export default SettingsTable;
