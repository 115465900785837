export const API_URL = process.env.REACT_APP_API_URL;
export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

// HR ROLES
export const HR_CONTROLLER = 'hr_controller';
export const HR_MANAGER = 'hr_manager';
export const HR_STAFF = 'hr_staff';
export const STAFF = 'staff';
export const ONBOARDING_USER = 'onboarding_user';
export const HELP_DESK_MANAGER = 'help_desk_manager';

// ACCOUNTING ROLES
export const ACCOUNTING_CONTROLLER = 'accounting_controller';
export const ACCOUNTING_MANAGER = 'accounting_manager';
export const ACCOUNTING_STAFF = 'accounting_staff';

// OPERATIONS ROLES
export const OPERATIONS_ADMIN = 'operations_admin';

// SUPER USER
export const SUPER_USER = 'superuser';

// DEPARTMENTS
export const HELP_AND_SUPPORT = 'help-and-support';

// UTILITIES
export const PENDING = 'pending';
export const UPLOADED = 'uploaded';
export const DELETED = 'deleted';
export const CANCELLED = 'cancelled';
export const APPROVED = 'approved';
export const DENIED = 'denied';
export const REJECTED = 'rejected';
export const PAID = 'paid';
export const OPEN = 'Open';

// PAGINATION
export const LIMIT_KEY = 'limit';
export const PAGE_KEY = 'page';
export const OFFSET_KEY = 'offset';

// Degree is removed from lookups
export const lookupMappings = {
  country: 'Country',
  currency: 'Currency',
  gender: 'Gender',
  marital_status: 'Marital Status',
  job_title: 'Job Title',
  education: 'Education',
  experience: 'Experience',
  job_department: 'Job Department',
  request_type: 'Document Request',
};

// Salary Actions
export const APPROVE = 'approve';
export const UNAPPROVE = 'unapprove';
export const PAY = 'pay';

export const SUPPORTED_IMAGE_FORMATS = ['png', 'jpg', 'jpeg'];

export const INTERNAL_MEETINGS = 'InternalMeetings';
export const COMPANY_HOLIDAYS = 'CompanyHolidays';
export const EVENTS = 'Events';

// Api tags for RTK Query
export const commonOnboardingApiTag = 'commonOnboardingApiTag';
export const commonJobsApiProvidesTag = 'commonJobsApiTag';

export const excelFileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

// TODO STATUSES
export const TODO = 'a';
export const COMPLETED = 'b';
export const OVERDUE = 'c';

// TODO PRIORTY VALUES
export const HIGH = 'a';
export const MEDIUM = 'b';
export const LOW = 'c';

export const acceptedFileTypes =
  '.doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx,.txt,.csv,.png,jpg,.jpeg,.webp,.ico,.bmp,.gif';

// LEAVES
export const LEAVES = 'Leaves';
export const HALF_LEAVE = 'Half Leave';
export const WFH = 'WFH';
export const WOH = 'Holiday Request';
