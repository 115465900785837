export const paginationReducer = (state, action) => {
  switch (action.type) {
    case 'rowsPerPage':
      return {
        page: 0,
        rowsPerPage: action.payload,
      };

    case 'page':
      return {
        ...state,
        page: action.payload,
      };

    default:
      return state;
  }
};

export const sortingReducer = (state, action) => {
  switch (action.type) {
    case 'order':
      return { ...state, order: action.payload };

    case 'orderBy':
      return { ...state, orderBy: action.payload };

    default:
      return state;
  }
};
