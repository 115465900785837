import { Remove } from '@mui/icons-material';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import propTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// API HOOKS
import { useUpdateBreakTimeManuallyMutation } from 'services/private/users';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';

// COMPONENTS & UTILITIES
import FormikTimePicker from 'shared/components/form/FormikTimePicker';
import SubmitBtn from 'containers/common/components/SubmitBtn';
import { editBreakFormInitialSchema } from '../utilities/formUtils';

function EditBreakForm({ responseHandler, afterRequestSend, breakData, breakForget }) {
  const [updateBreakTimeManually, { error, isSuccess }] = useUpdateBreakTimeManuallyMutation();
  const [initValues, setInitValues] = useState();
  useHandleApiResponse(error, isSuccess, 'Break Updated Successfully!');

  useEffect(() => {
    setInitValues({
      start_break_time: breakData.start_break_time,
      end_break_time: '',
    });
  }, [breakData]);

  return (
    <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={editBreakFormInitialSchema}
      onSubmit={async (values, { resetForm }) => {
        const { start_break_time: startBreakTime, end_break_time: endBreakTime } = values;
        if (!breakForget) {
          const response = await updateBreakTimeManually({
            start_break_time: startBreakTime,
            end_break_time: endBreakTime,
            is_custom: false,
            id: breakData?.id,
          });
          responseHandler(response);
          if (!response.error) {
            resetForm();
          }
          resetForm();
          afterRequestSend(true);
        } else {
          const response = await updateBreakTimeManually({
            end_break_time: endBreakTime,
            id: breakData?.id,
          });
          responseHandler(response);
          if (!response.error) {
            resetForm();
          }
          resetForm();
          afterRequestSend(true);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form className="d-flex align-items-start flex-column gap-3">
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <Typography className="required" variant="label">
                Time Interval
              </Typography>
            </Grid>

            <Grid item xs={12} md={9}>
              <Stack
                direction="row"
                className="justify-content-between align-items-center w-100"
                columnGap={1}
              >

                <FormikTimePicker name="start_break_time" disabled={breakForget} disablePast />

                <Remove />

                <FormikTimePicker name="end_break_time" disablePast />
              </Stack>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={1}>
            <SubmitBtn label="Update" isLoading={isSubmitting} />

            <Button variant="contained" color="secondary" onClick={afterRequestSend}>
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

EditBreakForm.defaultProps = {
  afterRequestSend: () => null,
  responseHandler: () => {},
  breakForget: false,
  breakData: {},
};

EditBreakForm.propTypes = {
  afterRequestSend: propTypes.func,
  responseHandler: propTypes.func,
  breakForget: propTypes.bool,
  breakData: propTypes.object,
};

export default EditBreakForm;
