import React from 'react';
import propTypes from 'prop-types';
import { Box, Button, Collapse, Grid } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// COMPONENTS & UTILS
import SectionLoader from 'containers/common/loaders/SectionLoader';
import VisaDetailGrid from './VisaDetailGrid';
import { visaDetailData, visaDocumentDetailData } from '../utilities/data';

function VisaDetailBox({ visa, handleViewFile, open, setOpen, isLoading }) {
  return (
    <Box className="bg-white px-3 py-5 ">
      {isLoading ? (
        <SectionLoader />
      ) : (
        <>
          <Grid container spacing={3}>
            {visaDetailData.map(item => (
              <VisaDetailGrid key={item?.value} item={item} visa={visa} handleViewFile={handleViewFile} />
            ))}
            {visa?.visa_documents?.length > 0 && (
              <Grid item xs={12}>
                <Button
                  size="small"
                  className="mt-2"
                  sx={{ bgcolor: 'action.hover' }}
                  onClick={() => setOpen(prev => !prev)}
                  endIcon={open ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                >
                  View More Documents
                </Button>
              </Grid>
            )}
          </Grid>
          <Collapse className="mt-4" in={open}>
            <Grid container gap={5}>
              {visa?.visa_documents?.map(doc => (
                <Grid container item spacing={3} key={doc?.id}>
                  {visaDocumentDetailData.map(item => (
                    <VisaDetailGrid
                      key={item?.value}
                      item={item}
                      visa={doc}
                      isDoc
                      handleViewFile={handleViewFile}
                    />
                  ))}
                </Grid>
              ))}
            </Grid>
          </Collapse>
        </>
      )}
    </Box>
  );
}

VisaDetailBox.propTypes = {
  visa: propTypes.object,
  handleViewFile: propTypes.func,
  setOpen: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  isLoading: propTypes.bool,
};

VisaDetailBox.defaultProps = {
  visa: {},
  handleViewFile: () => {},
  isLoading: true,
};

export default VisaDetailBox;
