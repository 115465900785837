import { yupLessTimeValidator, yupTimeValidator } from 'shared/helpers/utilityFunctions';
import * as yup from 'yup';

export const FACTS_FILTER_INIT_VALS = {
  employee__employee_name: '',
  start_date: '',
  end_date: '',
};

export const FACTS_FILTER_SCHEMA = yup.object({
  employee__employee_name: yup.string(),
  start_date: yup.date().typeError('Please enter a valid date'),
  end_date: yup
    .date()
    .typeError('Please enter a valid date')
    .min(yup.ref('start_date'), 'End date must be later than start date'),
});

export const checkOutFormInitialValue = {
  date: '',
  check_out_time: '',
};

export const checkOutFormInitialSchema = yup.object({
  date: yup.date().required('Required!').typeError('Invalid Date'),
  check_out_time: yup
    .string()
    .required('Required!')
    .test('test-time', 'Invalid time!', yupTimeValidator)
    .typeError('Invalid Time'),
});

export const checkInFormInitialValue = {
  check_in_time: '',
  forget_to_check_in_reason: '',
};

export const checkInFormInitialSchema = yup.object({
  check_in_time: yup
    .string()
    .required('Required!')
    .test('test-time', 'Invalid time!', yupTimeValidator)
    .test('test-less-time', 'Invalid time!', yupLessTimeValidator)
    .typeError('Invalid Time'),
  forget_to_check_in_reason: yup
    .string()
    .max(255, 'Maximum 255 characters allowed')
    .required('Required!')
});

export const editBreakFormInitialValue = {
  start_break_time: '',
  end_break_time: '',
};

export const editBreakFormInitialSchema = yup.object({
  start_break_time: yup.string(),
  end_break_time: yup.string().test('test-time', 'Invalid time!', yupTimeValidator).required('Required!'),
});
