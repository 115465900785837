import React from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import propTypes from 'prop-types';

// UTILITIES
import { proSettingsTableHeadCells } from '../../../utilities/data';

function SettingsTableHead({ numSelected, rowCount, onSelectAllRows }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected !== rowCount}
            checked={numSelected === rowCount && rowCount !== 0}
            onClick={onSelectAllRows}
          />
        </TableCell>

        {proSettingsTableHeadCells.map(cell => (
          <TableCell className="fw-bold" key={cell.id}>
            {cell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

SettingsTableHead.propTypes = {
  onSelectAllRows: propTypes.func.isRequired,
  numSelected: propTypes.number,
  rowCount: propTypes.number,
};

SettingsTableHead.defaultProps = {
  numSelected: 0,
  rowCount: 0,
};

export default SettingsTableHead;
