import React from 'react';
import { Box, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import propTypes from 'prop-types';

function EmptyRecordTable({ colSpan, label }) {
  return (
    <TableBody sx={{ height: '30vh' }}>
      <TableRow>
        <TableCell colSpan={colSpan}>
          <Box className="d-flex align-items-center justify-content-center">
            <Typography>{label}</Typography>
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

EmptyRecordTable.propTypes = {
  colSpan: propTypes.number,
  label: propTypes.string,
};

EmptyRecordTable.defaultProps = {
  colSpan: 1,
  label: 'No Record Found!'
};

export default EmptyRecordTable;
