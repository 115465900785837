import { Button, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import React from 'react';

// COMPONENTS & UTILS
import FormikField from 'shared/components/form/FormikField';
import SubmitBtn from 'containers/common/components/SubmitBtn';

function VisaRemarksForm({ onSubmit }) {
  return (
    <Formik
      initialValues={{
        remarks: '',
      }}
      onSubmit={values => {
        onSubmit({ remarks: values.remarks });
      }}
      validationSchema={yup.object({
        remarks: yup.string().max(255, 'Maximum 255 characters allowed').required('Remarks are required!'),
      })}
    >
      {({ isSubmitting, resetForm }) => (
        <Form>
          <FormikField
            label="Remarks"
            name="remarks"
            placeholder="Add remarks..."
            type="textarea"
            isRequired
          />

          <Stack mb={2} mt={2} direction="row" spacing={1}>
            <SubmitBtn label="Submit" isLoading={isSubmitting} />

            <Button variant="contained" color="secondary" onClick={resetForm}>
              Cancel
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

VisaRemarksForm.defaultProps = {
  onSubmit: () => {},
};

VisaRemarksForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default VisaRemarksForm;
