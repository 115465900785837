export const paginationReducer = (state, action) => {
  switch (action.type) {
    case 'page':
      return { ...state, page: action.payload };

    case 'rowsPerPage':
      return { page: 0, rowsPerPage: action.payload };

    default:
      return state;
  }
};

export const modalStateReducer = (state, action) => {
  switch (action.type) {
    case 'folder':
      return { ...state, isAddFolderModalOpen: action.payload };

    case 'file':
      return { ...state, isUploadFileModalOpen: action.payload };

    default:
      return state;
  }
};

export const viewModalReducer = (state, action) => {
  switch (action.type) {
    case 'modal':
      return { ...state, isViewModalOpen: action.payload };

    case 'viewFile':
      return { selectedFile: action.payload, isViewModalOpen: true };

    default:
      return state;
  }
};
