/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

// COMPONENTS
import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import AddInfoContextProvider from 'containers/portal/onboard/add-info/context/AddInfoContext';
import { JobContextProvider } from 'containers/portal/hr/Jobs/context/JobContext';
import RequestsContextProvider from 'containers/portal/requests/context/RequestsContext';
import DailyReports from 'containers/portal/dailyReports';
import VisaDetail from 'containers/portal/visa-process/components/VisaDetail';
import ScrollToTop from './ScrollToTop';

// ROUTES GAURDS
const PrivateRouteGuard = lazy(() => import('./PrivateRouteGuard'));
const ForgetCheckoutRoute = lazy(() => import('./ForgetCheckoutRoute'));

// ROUTES WRAPPER
const PublicRoutes = lazy(() => import('./PublicRoutes'));
const PrivateRoutes = lazy(() => import('./PrivateRoutes'));
const OnboardRoutes = lazy(() => import('./OnboardRoutes'));
const LayoutWrapper = lazy(() => import('containers/common/layout'));
const AdminAccessWrapper = lazy(() => import('containers/common/components/AdminAccessWrapper'));
const InterviewAccessWrapper = lazy(() => import('containers/common/components/InterviewAccessWrapper'));
const ClientsAccessWrapper = lazy(() => import('containers/portal/client/components/ClientsAccessWrapper'));

// AUTH ROUTES & MISCELLANEOUS ROUTES
const Login = lazy(() => import('containers/public-pages/login'));
const Unauthorized = lazy(() => import('containers/portal/misc/Unauthorized'));
const ResetPassword = lazy(() => import('containers/public-pages/reset-password'));
const ForgotPassword = lazy(() => import('containers/public-pages/forgot-password'));

// PUBLIC ROUTES
const Careers = lazy(() => import('containers/public-pages/careers'));
const CareerDetails = lazy(() => import('containers/public-pages/careers/career-details'));
const ApplyJob = lazy(() => import('containers/public-pages/careers/apply-job'));

// PORTAL ROUTES
const Dashboard = lazy(() => import('containers/portal/dashboard'));
const CompanyProfiles = lazy(() => import('containers/portal/hr/company/profiles'));
const AddCompanyProfile = lazy(() =>
  import('containers/portal/hr/company/profiles/components/AddCompanyProfile')
);

// COMPANIES
const Companies = lazy(() => import('containers/portal/hr/companies'));
const AddCompanies = lazy(() => import('containers/portal/hr/companies/components/AddCompanies'));
const EditCompanies = lazy(() => import('containers/portal/hr/companies/components/EditCompanies'));

// USERS
const UserProfile = lazy(() => import('containers/portal/user/profile'));
const Signature = lazy(() => import('containers/portal/user/signature'));
const Calendar = lazy(() => import('containers/portal/user/calendar'));

// EMPLOYEE
const Employees = lazy(() => import('containers/portal/hr/employees'));
const AddEmployee = lazy(() => import('containers/portal/hr/add-employee'));
const EmployeeProfile = lazy(() => import('containers/portal/hr/employee-profile'));

// PAYROLL
const Salary = lazy(() => import('containers/portal/hr/payroll/salary'));
const MasterSalary = lazy(() => import('containers/portal/hr/payroll/salary/MasterSalary'));
const PayrollGroups = lazy(() => import('containers/portal/settings/groups'));
const FinalPay = lazy(() => import('containers/portal/hr/payroll/final-pay'));
const ViewFinalPayForm = lazy(() =>
  import('containers/portal/hr/payroll/final-pay/components/ViewFinalPayForm')
);
const ViewSalaryDetails = lazy(() =>
  import('containers/portal/hr/payroll/salary/Employee/components/ViewSalaryDetails')
);

// LOOKUPS
const LeavesSettings = lazy(() => import('containers/portal/settings/leaves'));
const ShiftsSettings = lazy(() => import('containers/portal/settings/shifts'));
const LookupsSettings = lazy(() => import('containers/portal/settings/lookups'));
const ProStageSettings = lazy(() => import('containers/portal/settings/pro-stage'));
const DepartmentsSettings = lazy(() => import('containers/portal/settings/departments'));
const AddDepartment = lazy(() => import('containers/portal/settings/departments/add'));
const EditDepartment = lazy(() => import('containers/portal/settings/departments/edit'));

// TEAMS
const Teams = lazy(() => import('containers/portal/teams'));
const AddTeam = lazy(() => import('containers/portal/teams/add'));
const EditTeam = lazy(() => import('containers/portal/teams/edit'));
const TeamDetail = lazy(() => import('containers/portal/teams/details'));

// PROJECTS
const Projects = lazy(() => import('containers/portal/projects'));
const AddProject = lazy(() => import('containers/portal/projects/add'));
const EditProject = lazy(() => import('containers/portal/projects/edit'));
const ProjectDetail = lazy(() => import('containers/portal/projects/details'));

// CLIENT
const Clients = lazy(() => import('containers/portal/client'));
const AddClient = lazy(() => import('containers/portal/client/add'));
const EditClient = lazy(() => import('containers/portal/client/edit'));
const ClientDetail = lazy(() => import('containers/portal/client/details'));

// REQUESTS
const Requests = lazy(() => import('containers/portal/requests'));
const LeaveRequests = lazy(() => import('containers/portal/requests/leave-requests'));
const AdvanceRequests = lazy(() => import('containers/portal/requests/advance-request'));
const HolidayRequests = lazy(() => import('containers/portal/requests/holiday-requests'));
const HalfDayRequests = lazy(() => import('containers/portal/requests/half-day-requests'));
const DocumentRequests = lazy(() => import('containers/portal/requests/document-requests'));
const ResourceRequired = lazy(() => import('containers/portal/requests/resource-required'));
const WorkFromHomeRequests = lazy(() => import('containers/portal/requests/work-from-home-requests'));

// COMPANIES
const CompanyFiles = lazy(() => import('containers/portal/hr/company/files'));
const CompanyHolidays = lazy(() => import('containers/portal/hr/company/holidays'));
const EditCompanyProfile = lazy(() =>
  import('containers/portal/hr/company/profiles/components/EditCompanyProfile')
);

// JOBS
const Jobs = lazy(() => import('containers/portal/hr/Jobs'));
const AddJob = lazy(() => import('containers/portal/hr/Jobs/AddJob'));
const EditJob = lazy(() => import('containers/portal/hr/Jobs/EditJob'));
const JobDetail = lazy(() => import('containers/portal/hr/Jobs/JobDetail'));

// REPORTS
const MyReports = lazy(() => import('containers/portal/attendance'));
const TimeSummary = lazy(() => import('containers/portal/attendance/components/timeSummary/TimeSummary'));
const KpiTagsReport = lazy(() => import('containers/portal/attendance/components/kpiReport/KpiTagsReport'));
const ReportRequest = lazy(() =>
  import('containers/portal/attendance/components/productivityReport/ReportRequest')
);
const ProductivityReport = lazy(() =>
  import('containers/portal/attendance/components/productivityReport/ProductivityReport')
);
const EmployeeAttendance = lazy(() =>
  import('containers/portal/attendance/components/employeeAttendance/EmployeeAttendance')
);

// ASSETS
const AssetsLogs = lazy(() => import('containers/portal/assets-logs'));
const AddAssets = lazy(() => import('containers/portal/assets-logs/add'));
const EditAssets = lazy(() => import('containers/portal/assets-logs/edit'));

// VISA PROCESS
const VisaProcess = lazy(() => import('containers/portal/visa-process'));
const AddVisaProcess = lazy(() => import('containers/portal/visa-process/add-visa'));
const VisaProStages = lazy(() => import('containers/portal/visa-process/pro-stages'));

// GUEST ROUTES
const OfferLetter = lazy(() => import('containers/guest-pages/offer-letter'));

// ONBOARDING
const AddInfo = lazy(() => import('containers/portal/onboard/add-info'));
const OnboardEmployee = lazy(() => import('containers/portal/onboard/employee'));
const DirectOnboard = lazy(() => import('containers/portal/hr/onboarding/direct-onboard'));
const OnboardingQueue = lazy(() => import('containers/portal/hr/onboarding/onboarding-queue'));
const OnboardingQueueDetail = lazy(() =>
  import('containers/portal/hr/onboarding/onboarding-queue/queue-detail')
);

// HIRE PACKET
const CreateNewHirePacket = lazy(() => import('containers/portal/hr/hire-packet'));
const ViewHirePacket = lazy(() => import('containers/portal/hr/hire-packet/view-hire-packet'));

// CUSTOMERS
const Customers = lazy(() => import('containers/portal/hr/customers'));
const AddCustomer = lazy(() => import('containers/portal/hr/customers/add'));
const EditCustomer = lazy(() => import('containers/portal/hr/customers/edit'));

// DEMO
const RequestDemo = lazy(() => import('containers/public-pages/request-demo'));

// SETTINGS
const Settings = lazy(() => import('containers/portal/settings'));

// STORAGE
const Trash = lazy(() => import('containers/portal/storage/trash'));
const Storage = lazy(() => import('containers/portal/storage/main'));

// HELP AND SUPPORT
const HelpAndSupport = lazy(() => import('containers/portal/helpAndSupport'));
const TicketDetails = lazy(() => import('containers/portal/helpAndSupport/components/TicketDetails'));

// ENGAGEMENTS
const Engagements = lazy(() => import('containers/portal/engagements'));
const Todos = lazy(() => import('containers/portal/todos'));
const Interviews = lazy(() => import('containers/interviews'));

function AppRoutes() {
  return (
    <Suspense fallback={<GlobalLoader />}>
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<LayoutWrapper />}>
            {/* AUTH ROUTES */}
            <Route path="auth" element={<PublicRoutes />}>
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>

            {/* PORTAL ROUTES */}

            <Route path="/portal" element={<PrivateRoutes />}>
              <Route path="/portal" element={<PrivateRouteGuard />}>
                <Route index element={<Dashboard />} />
                <Route path="pages" element={<ForgetCheckoutRoute />}>
                  <Route element={<Outlet />}>
                    <Route path="user" element={<Outlet />}>
                      <Route path="profile" element={<UserProfile />} />
                      <Route path="calendar" element={<Calendar />} />
                      <Route path="signature" element={<Signature />} />
                    </Route>

                    <Route path="engagements" element={<Engagements />} />

                    <Route path="hr" element={<Outlet />}>
                      <Route path="company" element={<Outlet />}>
                        <Route path="profiles" element={<CompanyProfiles />} />
                        <Route path="profiles/add" element={<AddCompanyProfile />} />
                        <Route path="profiles/edit" element={<EditCompanyProfile />} />
                        <Route path="files" element={<CompanyFiles />} />
                        <Route path="holidays" element={<CompanyHolidays />} />
                      </Route>

                      <Route path="payroll" element={<Outlet />}>
                        <Route path="salaries-process" element={<Outlet />}>
                          <Route index element={<Salary />} />

                          <Route path="details" element={<ViewSalaryDetails />} />
                        </Route>

                        <Route path="master-salary" element={<Outlet />}>
                          <Route index element={<MasterSalary />} />
                        </Route>

                        <Route path="final-pay" element={<Outlet />}>
                          <Route index element={<FinalPay />} />
                          <Route path=":userId" element={<ViewFinalPayForm />} />
                        </Route>

                        <Route path="payroll-groups" element={<Outlet />}>
                          <Route index element={<PayrollGroups />} />
                        </Route>
                      </Route>

                      <Route path="employees" element={<Outlet />}>
                        <Route index element={<Employees />} />
                        <Route path=":userId/profile" element={<EmployeeProfile />} />
                        <Route path="add" element={<AddEmployee />} />
                      </Route>

                      <Route path="clients" element={<ClientsAccessWrapper />}>
                        <Route index element={<Clients />} />
                        <Route path="add" element={<AddClient />} />
                        <Route path=":id/detail" element={<ClientDetail />} />
                        <Route path=":id/edit" element={<EditClient />} />
                      </Route>

                      <Route path="sponsor" element={<Outlet />}>
                        <Route index element={<Companies />} />
                        <Route path="add" element={<AddCompanies />} />
                        <Route path="edit" element={<EditCompanies />} />
                      </Route>

                      <Route path="customers" element={<Outlet />}>
                        <Route index element={<Customers />} />
                        <Route path="add" element={<AddCustomer />} />
                        <Route path="edit/:id" element={<EditCustomer />} />
                      </Route>

                      {/* SCREENING */}
                      <Route path="screening" element={<Outlet />}>
                        <Route
                          path="jobs"
                          element={(
                            <JobContextProvider>
                              <AdminAccessWrapper />
                            </JobContextProvider>
                          )}
                        >
                          <Route index element={<Jobs />} />
                          <Route path="add" element={<AddJob />} />
                          <Route path=":id/detail" element={<JobDetail />} />
                          <Route path=":id/edit" element={<EditJob />} />
                        </Route>

                        <Route
                          path="interviews"
                          element={(
                            <InterviewAccessWrapper>
                              {' '}
                              <Interviews />{' '}
                            </InterviewAccessWrapper>
                          )}
                        />
                      </Route>

                      <Route path="hire-packet" element={<AdminAccessWrapper />}>
                        <Route path="create/:id" element={<CreateNewHirePacket />} />
                        <Route path="view/:id" element={<ViewHirePacket />} />
                      </Route>

                      {/* ONBOARDING */}
                      <Route path="onboarding" element={<AdminAccessWrapper />}>
                        <Route index element={<OnboardingQueue />} />
                        <Route path="detail/:id" element={<OnboardingQueueDetail />} />
                        <Route path="direct-onboard" element={<DirectOnboard />} />
                      </Route>
                    </Route>

                    <Route path="visa-process" element={<Outlet />}>
                      <Route path=":id" element={<VisaProcess />} />
                      <Route path="pro-stages" element={<VisaProStages />} />
                      <Route path="add" element={<AddVisaProcess />} />
                      <Route path=":id/detail" element={<VisaDetail />} />
                      <Route path=":id/edit" element={<VisaProcess />} />
                    </Route>

                    <Route path="teams" element={<Outlet />}>
                      <Route index element={<Teams />} />
                      <Route path="add" element={<AddTeam />} />
                      <Route path=":id/detail" element={<TeamDetail />} />
                      <Route path=":id/edit" element={<EditTeam />} />
                    </Route>

                    <Route path="requests" element={<Outlet />}>
                      <Route
                        index
                        element={(
                          <RequestsContextProvider>
                            {' '}
                            <Requests />{' '}
                          </RequestsContextProvider>
                        )}
                      />
                      <Route
                        path="leave-requests"
                        element={(
                          <RequestsContextProvider>
                            {' '}
                            <LeaveRequests />{' '}
                          </RequestsContextProvider>
                        )}
                      />
                      <Route
                        path="work-from-home-requests"
                        element={(
                          <RequestsContextProvider>
                            {' '}
                            <WorkFromHomeRequests />{' '}
                          </RequestsContextProvider>
                        )}
                      />
                      <Route
                        path="half-day-requests"
                        element={(
                          <RequestsContextProvider>
                            {' '}
                            <HalfDayRequests />{' '}
                          </RequestsContextProvider>
                        )}
                      />
                      <Route
                        path="holiday-requests"
                        element={(
                          <RequestsContextProvider>
                            {' '}
                            <HolidayRequests />{' '}
                          </RequestsContextProvider>
                        )}
                      />
                      <Route
                        path="advance-request"
                        element={(
                          <RequestsContextProvider>
                            {' '}
                            <AdvanceRequests />{' '}
                          </RequestsContextProvider>
                        )}
                      />
                      <Route path="document-requests" element={<DocumentRequests />} />
                      <Route path="resource-required" element={<ResourceRequired />} />
                    </Route>

                    <Route path="projects" element={<Outlet />}>
                      <Route index element={<Projects />} />
                      <Route path="add" element={<AddProject />} />
                      <Route path=":id/detail" element={<ProjectDetail />} />
                      <Route path=":id/edit" element={<EditProject />} />
                    </Route>

                    <Route path="assets-logs" element={<Outlet />}>
                      <Route index element={<AssetsLogs />} />
                      <Route path="add" element={<AddAssets />} />
                      <Route path=":id/edit" element={<EditAssets />} />
                    </Route>

                    <Route path="my-attendance" element={<Outlet />}>
                      <Route index element={<MyReports />} />
                      <Route path="time-summary" element={<TimeSummary />} />
                      <Route path="productivity-report" element={<ProductivityReport />} />
                      <Route path="kpi-adherence-report" element={<KpiTagsReport />} />
                      <Route path="employee-attendance" element={<EmployeeAttendance />} />
                      <Route path="report-request" element={<ReportRequest />} />
                    </Route>

                    <Route path="todos" element={<Todos />} />

                    <Route path="storage" element={<Outlet />}>
                      <Route path="trash" element={<Trash />} />
                      <Route path="main" element={<Storage />} />
                    </Route>

                    <Route path="settings" element={<Outlet />}>
                      <Route index element={<Settings />} />
                      <Route path="lookups" element={<LookupsSettings />} />
                      <Route path="leaves" element={<LeavesSettings />} />
                      <Route path="shifts" element={<ShiftsSettings />} />
                      <Route path="pro-stages" element={<ProStageSettings />} />
                      <Route path="departments" element={<Outlet />}>
                        <Route index element={<DepartmentsSettings />} />
                        <Route path="add" element={<AddDepartment />} />
                        <Route path="edit/:id" element={<EditDepartment />} />
                        {/* <Route path=":id" element={<DepartmentsSettings />} /> */}
                      </Route>
                    </Route>

                    <Route path="help-and-support" element={<Outlet />}>
                      <Route path=":id" element={<TicketDetails />} />
                      <Route index element={<HelpAndSupport />} />
                    </Route>

                    <Route path="unauthorized" element={<Unauthorized />} />
                  </Route>
                </Route>
              </Route>
              <Route path="/portal/pages/daily-tasks-report" element={<DailyReports />} />
            </Route>

            {/* GUEST ROUTES */}
            <Route path="/guest" element={<Outlet />}>
              <Route path="offer-letter/:id" element={<OfferLetter />} />
            </Route>

            {/* ONBOARD */}
            <Route path="/onboard" element={<OnboardRoutes />}>
              <Route path="employee" element={<OnboardEmployee />} />
              <Route
                path="add-info"
                element={(
                  <AddInfoContextProvider>
                    <AddInfo />
                  </AddInfoContextProvider>
                )}
              />
            </Route>

            {/* PUBLIC ROUTES */}
            <Route index element={<Careers />} />

            <Route path="career" element={<Outlet />}>
              <Route path="details/:jobId" element={<CareerDetails />} />
              <Route path="apply/:jobId" element={<ApplyJob />} />

              <Route index element={<Careers />} />
            </Route>

            <Route path="request-demo" element={<RequestDemo />} />

            <Route path="*" element={<h1>Not Found!</h1>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default AppRoutes;
