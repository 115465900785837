import React, { useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container, Modal, Stack, Typography } from '@mui/material';

// API & HOOKS
import { useGetVisaProcessByIdQuery } from 'services/private/visa';
import useGetEmployeeRoles from 'customHooks/useGetEmployeeRoles';

// STYLES
import { contentModalStyles } from 'styles/mui/common/modal-styles';

// COMPONENTS & UTILS
import { viewModalReducer } from 'containers/portal/hr/company/files/utilities/reducerActions';
import ViewDocModal from 'containers/common/components/ViewDocModal';
import SettingsTable from 'containers/portal/settings/pro-stage/components/table/SettingsTable';
import VisaDetailBox from './VisaDetailBox';

function VisaDetail() {
  const navigate = useNavigate();
  const { id: visaID } = useParams();

  // API HOOKS
  const { isHRController, isSuperUser, isHRManager } = useGetEmployeeRoles();
  const isController = isHRController || isHRManager || isSuperUser;

  const [viewModal, setViewModal] = useReducer(viewModalReducer, {
    isViewModalOpen: false,
    selectedFile: null,
  });
  const { isViewModalOpen, selectedFile } = viewModal;
  const { data, isLoading, isFetching } = useGetVisaProcessByIdQuery(visaID);

  // HANDLERS
  const handleViewFile = (fileValue, fileType) => {
    setViewModal({ type: 'viewFile', payload: { file: fileValue, type: fileType } });
  };

  const toggleViewModal = () => {
    setViewModal({ type: 'modal', payload: !isViewModalOpen });
  };
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Box className="d-flex align-items-start justify-content-between mb-4">
        <Typography variant="pageTitle">Visa Detail</Typography>
        <Button size="small" onClick={() => navigate(-1)} variant="contained">
          Back
        </Button>
      </Box>

      <VisaDetailBox
        open={open}
        setOpen={setOpen}
        isLoading={isLoading || isFetching}
        visa={data}
        handleViewFile={handleViewFile}
      />

      {isController && data?.visa_status !== 'approved' && (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" className="mt-5">
            <Typography variant="pageTitle">Select PRO Stages</Typography>
          </Stack>
          <Box className="bg-white mt-3 p-3">
            <SettingsTable visa={data} proSelection />
          </Box>
        </>
      )}

      {/* VIEW FILE MODAL */}
      <Modal open={isViewModalOpen} onClose={toggleViewModal}>
        <Box sx={contentModalStyles}>
          <ViewDocModal selected={selectedFile} toggle={toggleViewModal} />
        </Box>
      </Modal>
    </Container>
  );
}

export default VisaDetail;
