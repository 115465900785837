export const visaTypeOptions = [
  { label: 'Visit Visa', value: 'Visit Visa' },
  { label: 'Work Visa', value: 'Work Visa' },
  { label: 'Residence Visa', value: 'Residence Visa' },
];

export const visaStageTableHeadings = [
  {
    id: 'activity',
    label: 'Activity',
  },
  {
    id: 'cost',
    label: 'Cost',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

export const visaActivities = [
  {
    id: 0,
    label: "Candidate's acceptance of the offer letter",
    menu: true,
  },
  {
    id: 1,
    label: 'Candidate submits all documents',
    menu: true,
  },
  {
    id: 2,
    label: 'Sending the offer letter to the ministry',
    menu: true,
  },
  {
    id: 3,
    label: 'Work Permit Allocation',
  },
  {
    id: 5,
    label: 'E-visa issuance',
  },
  {
    id: 6,
    label: 'Entry into the country',
    menu: true,
  },
  {
    id: 7,
    label: 'Medical test',
  },
  {
    id: 8,
    label: 'Emirates ID printing',
  },
  {
    id: 9,
    label: 'Labor contract',
  },
  {
    id: 10,
    label: 'Local Insurance',
  },
  {
    id: 11,
    label: 'Visa Issuance',
  },
  {
    id: 12,
    label: 'Remarks for tracking visa status (Visa allotted, etc.)',
  },
];

export const VISA_STATUSES = [
  { label: 'Approved', value: 'approved' },
  { label: 'Pending', value: 'pending' },
  { label: 'Rejected', value: 'rejected' },
];

export const visaDetailData = [
  { label: 'Visa Number', value: 'visa_number' },
  { label: 'Visa Status', value: 'visa_status', menu: true },
  { label: 'Visa Type', value: 'visa_type' },
  { label: 'Visa Issue Date', value: 'visa_issue_date' },
  { label: 'Visa Expiry Date', value: 'visa_expiry_date' },
  {
    label: 'Visa File',
    value: 'visa_file',
    fileName: 'visa_file_name',
    fileType: 'visa_file_type',
    isFile: true,
  },
  { label: 'Visa remarks', value: 'remarks', colSpan: 12 },
  { label: 'Passport Number', value: 'passport_number' },
  { label: 'Passport Issue Date', value: 'passport_issue_date' },
  { label: 'Passport Expiry Date', value: 'passport_expiry_date' },
  {
    label: 'Passport File',
    value: 'passport_file',
    fileName: 'passport_file_name',
    fileType: 'passport_file_type',
    isFile: true,
  },
];

export const visaDocumentDetailData = [
  { label: 'Document Name', value: 'document_name' },
  { label: 'Document Issue Date', value: 'document_issue_date' },
  { label: 'Document Expiry Date', value: 'document_expiry_date' },
  { label: 'Document Status', value: 'document_status', menu: true },
  {
    label: 'Document File',
    value: 'document_file',
    fileName: 'document_file_name',
    fileType: 'document_file_type',
    isFile: true,
  },
  { label: 'Document Remarks', value: 'remarks' },
];

export const visaTableHeadings = [
  {
    id: 'visa_number',
    label: 'Visa Number',
  },
  {
    id: 'visa_status',
    label: 'Visa Status',
  },
  {
    id: 'visa_type',
    label: 'Visa Type',
  },
  {
    id: 'visa_expiry_date',
    label: 'Visa Expiry Date',
  },
];
